import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import services from "../images/services.png"
import ServiceCard from "../components/serviceCard"

import webDesign from "../images/webDesign.png"
import social from "../images/social.png"
import seo from "../images/seo.png"
import socialmanagement from "../images/socialmanagement.png"
import emailmarketing from "../images/emailmarketing.png"
import landingpage from "../images/landingpage.png"
import logo from "../images/smLogoblueW.png"
import cmarket from "../images/coversationalMarketing.png"
import google from "../images/googleAds.png"
import textMarketing from "../images/textMarketing.png"

const cards = [
  {
    title: "CONVERSATIONAL MARKETING",
    detail:
      "This is the Way to build relationship and create authentic experiences for your customers. Using the Latest Approach in Conversational Marketing, our team will design and implement targeted messaging and intelligent chatbots to engage with people that visit your website or see your ads. This will result in a much Higher Conversion and increased sales for your business.",
    image: cmarket,
  },
  {
    title: "WEBSITE DESIGN",
    detail:
      "Everyone uses the internet to find information nowadays and if You want to make your business visible and competitive, then you need an attention-grabbing website. Having a Website with Unique Design will give you automatic online presence that is another chance to introduce people to your brand and It will be your business card that will help people find your business and get more information about it.",
    image: webDesign,
  },
  {
    title: "SOCIAL MEDIA ADS",
    detail:
      "78% of salespeople outsell their peers by using social media for their business. Mainly because 93% of shoppers buying decisions are influenced by social media.  This type of advertising will help you Establish Your Brand and acquire new customers, while getting quality traffic and increased revenue. We will help you find the right ad types and choose a platform in order to expand the full potential of your business.",
    image: social,
  },
  {
    title: "GOOGLE ADS",
    detail:
      "Advertising on the Google Display Network is a great way to grow your business.The Skyrocket DIgital Marketing Team will tailor custom Advertising Strategies and Campaigns that are optimized for Growth and maximize the benefits of Remarketing and Retargeting, Google Display Ads, Google Shopping and more.",
    image: google,
  },
  {
    title: "SEARCH ENGINE OPTIMIZATION",
    detail:
      "Our highly customised SEO methods are formulated through an in-depth and extensive study of multiple factors. We help businesses gain top positions in the natural search results by building working digital tactics to deliver Higher Leads and Conversions. Skyrocket DM only uses ethical and white hat methods to secure top positions in search rankings in order to achieve and maintain more effective and long-lasting results.",
    image: seo,
  },
  {
    title: "Social Media Management",
    detail:
      "Our Innovative, creative and trending social media management strategies coupled with experience and skills in all existing social media platforms will take your brand recognition to the next level. Using Profit-Driven tactics, We will help you achieve a better brand visibility and step up business credibility among all active social media users.",
    image: socialmanagement,
  },
  {
    title: "RETARGETING CAMPAIGNS ",
    detail:
      "On average only 2% of website visitors convert during their first visit. Retargeting is a great way to promote brand awareness and make all your visitors come back and buy the products they saw on your Website. Skyrocket DM will help you turn your visitors into clients with retargeting strategies that not only focus on short-term sales but also on building consumer trust for your brand.",
    image: emailmarketing,
  },
  {
    title: "SALES FUNNEL",
    detail:
      "If You are Looking to Maximize Your Revenue and Achieve even Better Results in Your Online Journey, then you will need a Sales Funnel. We will Help you increase your conversion rates by creating custom Sales Funnels that represent your product or service in an engaging and unique way, leading to a Much Higher Revenue for your Business. Our Team will not only help you Sell a Product or Generate New leads, but we will also implement an Upselling Strategy that will exponentially increase Revenue and ROI.",
    image: landingpage,
  },
  {
    title: "SMS MARKETING",
    detail:
      "Text message marketing has been proven to help increase the overall engagement with your ideal customer in order to boost the number of quality leads and high conversions. As people become more attached to their phones, this type of marketing will help you send time-sensitive offers and updates to establish the most direct line of contact and effectively diversify your marketing channels for better results.",
    image: textMarketing,
  },
]

const ServicesPage = props => (
  <Layout>
    <SEO title="Services At  Digital Marketing" />
    <div className="flex-row flex-wrap px-10 mb-20 md:flex">
      <div className="px-4 flex-1">
        <h1 className="text-5xl font-extrabold mb-1 xs:text-lg uppercase">
          Services
        </h1>
        <img src={logo} alt="Skyrocket Digital Marketing" className={"h-16"} />
        <p>
          <strong>DIGITAL MARKETING STRATEGY CONSULTANT</strong>
          <br />
          Our Highly Skilled Professionals will evaluate your current marketing
          strategy and will show you new angles to target consumers in the right
          ways to see the results that your business needs. Based on your
          existing business model we will aim to measure the maximum potential
          that you can achieve and create a strategy that will work successfully
          for your brand.
        </p>
        <Link to="/contact/">
          <button className="bg-skblue-100 hover:bg-blue-200 hover:shadow text-white font-bold py-2 px-4 rounded shadow-2xl focus:outline-none focus:shadow-outline">
            Let's Get Started
          </button>
        </Link>
      </div>
      <div className="px-4 flex-1">
        <img src={services} alt={"Services At Skyrocket Marketing"} />
      </div>
    </div>
    <div className="flex flex-wrap flex-row px-10  mb-32">
      {cards.map(card => (
        <ServiceCard
          title={card.title}
          detail={card.detail}
          image={card.image}
        />
      ))}
    </div>
  </Layout>
)

export default ServicesPage
