import React from "react"

const ServiceCard = props => (
  <div class="w-full sm:w-1/2 md:w-1/3 mb-4 px-2">
    <div class="relative bg-white rounded border">
      <picture class="block bg-gray-200 border-b">
        <img class="block" src={props.image} alt={props.title} />
      </picture>
      <div class="p-4">
        <h3 class="text-lg font-bold">{props.title}</h3>
        <time class="block mb-2 text-sm text-gray-600"></time>
        <p>{props.detail}</p>
      </div>
    </div>
  </div>
)

export default ServiceCard
